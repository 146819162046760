import React from "react";
import {
  Heading,
  ListItem,
  Link,
  Strong,
  Paragraph,
  UnorderedList,
} from "evergreen-ui";
import { FaWhatsapp } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import styles from "./Home.module.css";

export default function Home(props) {
  return (
    <div className={styles.pageGridContainer}>
      <div className={`${styles.pageGridItem} ${styles.item1}`}>
        <Heading marginBottom="0.5rem">Halo, namaku...</Heading>
        <Heading size={900} className={`${styles.myName} slidink`}>
          Arga Saragih
        </Heading>
        <hr />
        <Paragraph fontSize={16}>Aku membuat situs</Paragraph>
      </div>
      <div className={`${styles.pageGridItem} ${styles.item2}`}>
        <Heading marginBottom="0.5rem">Rekam Jejak</Heading>
        <Paragraph>
          <strong>Banyak berkontribusi</strong> dalam membuat:
          <UnorderedList>
            <ListItem fontSize={16}>
              2020 | Hakken! E-commerce{" "}
              <Link
                href="https://www.hakkenonline.com/"
                target="_blank"
                noopener
                noreferrer
              >
                (buka)
              </Link>
            </ListItem>
            <ListItem fontSize={16}>
              2020 | Aphrozone Singapore E-commerce{" "}
              <Link
                href="https://aphrozone.sg/"
                target="_blank"
                noopener
                noreferrer
              >
                (buka)
              </Link>
            </ListItem>
            <ListItem fontSize={16}>
              2019 | DT Construction Group Online Quotation System{" "}
              <Link
                href="https://getquote.dtcg.sg/"
                target="_blank"
                noopener
                noreferrer
              >
                (buka)
              </Link>
            </ListItem>
            <ListItem fontSize={16}>
              2017 | Treaty Room Kementerian Luar Negeri RI{" "}
              <Link
                href="https://treaty.kemlu.go.id/"
                target="_blank"
                noopener
                noreferrer
              >
                (buka)
              </Link>
            </ListItem>
          </UnorderedList>
        </Paragraph>
        <Paragraph>
          <strong>Berkontribusi sebagian</strong> dalam membuat:
          <UnorderedList>
            <ListItem fontSize={16}>
              2019 | Ditjen AHU Online{" "}
              <Link
                href="https://ahu.go.id/"
                target="_blank"
                noopener
                noreferrer
              >
                (buka)
              </Link>
            </ListItem>
          </UnorderedList>
          <UnorderedList>
            <ListItem fontSize={16}>
              2019 | UOB Utilities Campaign{" "}
              <Link
                href="https://www.uob.com.sg/utilities/"
                target="_blank"
                noopener
                noreferrer
              >
                (buka)
              </Link>
            </ListItem>
          </UnorderedList>
          <UnorderedList>
            <ListItem fontSize={16}>
              2019 | Mangala Vihara Buddhist Temple Website{" "}
              <Link
                href="https://mv.org.sg/"
                target="_blank"
                noopener
                noreferrer
              >
                (buka)
              </Link>
            </ListItem>
          </UnorderedList>
          <UnorderedList>
            <ListItem fontSize={16}>
              2018 | Skleem App{" "}
              <Link
                href="https://www.skleem.co/"
                target="_blank"
                noopener
                noreferrer
              >
                (buka)
              </Link>
            </ListItem>
          </UnorderedList>
        </Paragraph>
      </div>
      <div className={`${styles.pageGridItem} ${styles.item3}`}>
        <Heading marginBottom="0.5rem">Ada situs yang ingin kamu buat?</Heading>
        <Paragraph>
          Ayo kita bicarakan lewat:
          <UnorderedList style={{ listStyle: "none" }}>
            <ListItem fontSize={16}>
              <FaWhatsapp style={{ position: "relative", top: "2px" }} />{" "}
              WhatsApp{" "}
              <Link href="https://api.whatsapp.com/send?phone=6281276763324&text=Halo%2C%20Arga.%20Saya%20tertarik%20untuk%20memiliki%20situs.">
                (+62) 812 7676 3324
              </Link>
            </ListItem>
            <ListItem fontSize={16}>
              <MdEmail style={{ position: "relative", top: "2px" }} /> Email{" "}
              <Link href="mailto:arga.saragih@gmail.com?subject=Saya tertarik untuk memiliki situs">
                (Buat pesan)
              </Link>
            </ListItem>
          </UnorderedList>
        </Paragraph>
      </div>
      <div className={`${styles.pageGridItem} ${styles.item4}`}>
        <Heading marginBottom="0.5rem">Sedikit tentang aku</Heading>
        <UnorderedList>
          <ListItem fontSize={16}>
            Seorang <em>lead software engineer</em> di{" "}
            <Link
              href="https://alumagubi.co.id"
              target="_blank"
              noreferrer
              noopener
            >
              Alumagubi
            </Link>
          </ListItem>
          <ListItem fontSize={16}>
            Mengelola{" "}
            <Link
              href="https://ruangbaca.online"
              target="_blank"
              noopener
              noreferrer
            >
              Ruang Baca
            </Link>
            , sebuah perpustakaan online
          </ListItem>
          <ListItem fontSize={16}>
            Alumni Nanyang Technological University dan SMA Sutomo 1
          </ListItem>
          <ListItem fontSize={16}>
            Berdomisili di Cengkareng, Jakarta Barat
          </ListItem>
          <ListItem fontSize={16}>
            Asal dari{" "}
            <Link
              href="https://youtu.be/xliyYefXFXQ?t=40"
              target="_blank"
              noreferrer
              noopener
            >
              Medan
            </Link>
            , Sumatera Utara
          </ListItem>
        </UnorderedList>
        <Heading marginTop="default" size={600}>
          <Strong>Terima kasih mau berkunjung. Salam sehat.</Strong>
        </Heading>
      </div>
    </div>
  );
}
